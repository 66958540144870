<template>
  <InputFoundation
    :id="id"
    v-model="inputValue"
    :mask="mask"
    :label="label"
    :disabled="disabled"
    :readonly="readonly"
    :placeholder="placeholder"
    :required="required"
    :aria-required="required"
    :status-type="status"
    :maxlength="maxLength"
    type="text"
    v-bind="inputAttr"
    :autocomplete="autocomplete"
    v-on="$listeners"
  >
    <template #leading-slot>
      <BaseIcon v-if="leadingIcon" :icon="leadingIcon" colors="currentColor" />
    </template>
    <template #trailing-slot>
      <span v-if="trailingIcon">
        <RoundedIcon
          v-if="iconHasHover"
          :icon-name="trailingIcon"
          :has-hover="iconHasHover"
          :hover-color="NebraskaColors.backgroundHover"
          text-color="currentColor"
          main-color="transparent"
          :tabindex="0"
          role="button"
          :aria-label="trailingIconLabel"
          @click.native="trailingClick"
          @keyup.enter="trailingClick"
          @keyup.space="trailingClick"
        />
        <RoundedIcon
          v-else
          :icon-name="trailingIcon"
          :has-hover="iconHasHover"
          :hover-color="NebraskaColors.backgroundHover"
          text-color="currentColor"
          main-color="transparent"
        />
      </span>
      <StatusTypeIcon v-else-if="status" :status-type="status" />
    </template>
    <template #bottom-slot>
      <HelperText
        v-if="helperText"
        :id="helperTextId"
        :text="helperText"
        :status-type="status"
      />
    </template>
  </InputFoundation>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import { HelperText } from '@/components/helper-text';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import InputFoundation from '@/foundation/input/InputFoundation.vue';
import { InputStatusType } from '@/foundation/types';
import { RoundedIcon } from '@/components/legacy';
import { StatusTypeIcon } from '@/components/status-type-icon';

type InputValue = string | number;

@Component({
  name: 'InputText',
  components: {
    InputFoundation,
    BaseIcon,
    RoundedIcon,
    StatusTypeIcon,
    HelperText
  }
})
export default class InputText extends Vue {
  /**
   * Rótulo (label) exibido no input.
   */
  @Prop({ type: String, required: false })
  private label?: string;

  /**
   * Valor inicial do input
   */
  @Prop({ type: [String, Number], default: '', required: false })
  private value!: InputValue;

  /**
   * Caso o input seja somente leitura (permite selecionar o valor)
   */
  @Prop({ type: Boolean, required: false, default: false })
  private readonly!: boolean;

  /**
   * Caso o input esteja desativado
   */
  @Prop({ type: Boolean, required: false, default: false })
  private disabled!: boolean;

  /**
   * O texto exibido dentro do input, quando não há valor digitado
   */
  @Prop({ type: String, required: false })
  private placeholder?: string;

  /**
   * O atributo usado para indicar que a entrada do usuário é obrigatória.
   */
  @Prop({ type: Boolean, required: false, default: false })
  private required?: boolean;

  /**
   * O ícone exibido à esquerda dentro do input utilizado dentro do leadingSlot.
   * É possível passar outro tipo de elemento dentro também.
   */
  @Prop({ type: String, required: false })
  private leadingIcon?: string;

  /**
   * O ícone exibido à direita dentro do input utilizado dentro do trailingSlot.
   * É possível passar outro tipo de elemento dentro também.
   */
  @Prop({ type: String, required: false })
  private trailingIcon?: string;

  /**
   * A mensagem de apoio exibida abaixo do input utilizado dentro do bottomSlot.
   * É possível passar outro tipo de elemento dentro também.
   */
  @Prop({ type: String, required: false })
  private helperText?: string;

  /**
   * Caso o usuário deseje passar um click para o ícone do trailingSlot, a prop adiciona um hover padrão ao ícone, indicando interação com o elemento.
   */
  @Prop({ type: Boolean, default: false })
  private iconHasHover!: boolean;

  /**
   * Quando o trailingIcon é clicável, o mesmo é lido por tecnologias assistivas.
   * Sendo assim, é necessário passarmos um label descritivo para o ícone.
   */
  @Prop({ type: String, required: false })
  private trailingIconLabel?: string;

  /**
   * Caso o usuário deseje passar uma máscara para o InputText.
   */
  @Prop({ type: [String, Array] })
  private mask?: string | string[];

  /**
   * O id está relacionado diretamente com o autocomplete, se você optar por não passar um id, consequentemente não irá funcionar o autocomplete.
   * [Nessa documentação](https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete#values) poderá ver alternativas do que passar no id para que as sugestões do autocomplete estejam de acordo com o desejado.
   * Você pode também utilizar o "off" para que não seja exibida as sugestões do autocomplete.
   * @values off
   */
  @Prop({ type: String, required: false, default: 'on' })
  private autocomplete?: string;

  /**
   * Se não passado nenhum id, ele será gerado aleatóriamente.
   */
  @Prop({ type: String, required: false })
  private id?: string;

  /**
   * Possibilita definir estados de sucesso e erro.
   * @values default, error, success
   */
  @Prop({
    type: String,
    default: InputStatusType.Default
  })
  private status!: InputStatusType;

  /**
   * Adiciona um limite de caracteres para o input
   */
  @Prop({ type: Number })
  readonly maxLength?: number;

  private NebraskaColors = NebraskaColors;

  private trailingClick() {
    this.iconClick();
  }

  /**
   * Evento de click dos itens dentro do trailingSlot.
   */
  @Emit('icon-click')
  public iconClick() {
    // Função que emite evento
  }

  /**
   * Retorna o valor atual do input, a cada mudança
   */
  @Emit('input')
  public input(value: InputValue) {
    return value;
  }

  get helperTextId() {
    const randomId = Math.random().toString(16).slice(2);
    return `helper-text-${randomId}`;
  }

  private get inputValue() {
    return this.value;
  }

  // Como usamos o inputValue como nosso v-model, é necessário o setter dessa propriedade também
  private set inputValue(value) {
    this.input(value);
  }

  get inputAttr() {
    return this.helperText ? { 'aria-describedby': this.helperTextId } : '';
  }
}
</script>
