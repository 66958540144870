<template>
  <div data-testid="navbar-container" :class="computedNavBarClass">
    <div class="navbar-container__side navbar-container__left">
      <slot name="left" />
    </div>
    <div class="navbar-container__center">
      <slot name="center" />
    </div>
    <div class="navbar-container__side navbar-container__right">
      <slot name="right" />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'NavBar'
})
export default class NavBar extends Vue {
  /**
   * Indica se o NavBar deve ser transparente
   */
  @Prop({ type: Boolean, default: false })
  private transparent!: boolean;

  /**
   * Indica se o NavBar deve ter a position sticky
   */
  @Prop({ type: Boolean, default: false })
  private sticky!: boolean;

  /**
   * Indica se deve remover o border-bottom
   */
  @Prop({ type: Boolean, default: false })
  private hideBorder!: boolean;

  private get computedNavBarClass() {
    return [
      'navbar-container',
      { 'navbar-container--transparent': this.transparent },
      { 'navbar-container--sticky': this.sticky },
      { 'navbar-container--hide-border': this.hideBorder }
    ];
  }
}
</script>

<style lang="less" scoped>
@onMobile: ~'only screen and (max-width: 640px)';

.navbar-container {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: @element-primary;
  background-color: @background-secondary;
  padding: 0px @size-spacing-x500;
  border-bottom: @size-border-x400 solid @divider-primary;

  &--sticky {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 20;
  }

  &--transparent {
    background-color: transparent;
    color: @element-over-image;
    border-bottom: @size-border-x400 solid @divider-navbar;
  }

  &--hide-border {
    border-bottom: none;
  }

  @media @onMobile {
    height: 56px;
    padding: 0 @size-spacing-x350;
  }
}

.navbar-container__side {
  flex: 1 0 24px;
  white-space: nowrap;
}

.navbar-container__left {
  text-align: left;
}

.navbar-container__right {
  text-align: right;
}

.navbar-container__center {
  width: inherit;
  flex: 1 1 auto;
  padding: 0;
  text-align: center;
  .text-p-5();
  font-weight: 600;
}
</style>
