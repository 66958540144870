<template>
  <div :class="['flow-conclusion', modifiers]" data-testid="flow-conclusion">
    <NavBar
      :transparent="isTransparent"
      :sticky="showNavbar"
      :class="conclusionStepClass"
    >
      <template #right>
        <ButtonIcon
          v-if="showNavbar"
          :colors="closeButtonColors"
          label="Fechar"
          icon="EF0030"
          @click="emitClose"
        />
      </template>
    </NavBar>
    <section class="flow-conclusion__container">
      <div class="flow-conclusion__content">
        <div class="flow-conclusion__header">
          <HelperViewStatus
            class="flow-conclusion__helper-view"
            :title="title"
            :description="description"
            :status="status"
          />
        </div>
        <div class="flow-conclusion__details">
          <slot />
        </div>
      </div>
    </section>
    <ButtonContainer
      v-if="showButtonContainer"
      class="flow-conclusion__button-container"
    >
      <template #button-primary>
        <slot name="button-primary" />
      </template>
      <template #button-secondary>
        <slot name="button-secondary" />
      </template>
    </ButtonContainer>
  </div>
</template>

<script lang="ts">
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { ConclusionStatus } from './types';
import { ButtonContainer } from '@/components/button-container';
import { HelperViewStatus } from '@/components/helper-view';
import { NavBar } from '@/components/navbar';
import ButtonIcon from '@/foundation/button-icon/ButtonIcon.vue';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import throttle from 'lodash.throttle';

@Component({
  name: 'ConclusionStep',
  components: {
    HelperViewStatus,
    NavBar,
    ButtonIcon,
    ButtonContainer
  }
})
export default class ConclusionStep extends Vue {
  /**
   * Status do fluxo, podem ser: positive, negative e information
   */
  @Prop({
    type: String,
    required: true,
    validator: (propValue: ConclusionStatus) => {
      return Object.values(ConclusionStatus).includes(propValue);
    }
  })
  public readonly status!: ConclusionStatus;

  /**
   * Título principal a ser exibido
   */
  @Prop({ type: String, required: true })
  public readonly title!: string;

  /**
   * Descrição a ser exibido
   */
  @Prop({ type: String })
  public readonly description!: string;

  /**
   * Define se irá renderizar o ButtonContainer
   */
  @Prop({ type: Boolean, default: true })
  public readonly showButtonContainer!: boolean;

  /**
   * Define se irá renderizar o Navbar
   */
  @Prop({ type: Boolean, default: true })
  public readonly showNavbar!: boolean;

  /**
   * Abstração de uso das cores do Nebraska pelo template
   */
  public readonly NebraskaColors = NebraskaColors;

  /**
   * Modificadores de status
   */
  public get modifiers() {
    return [`flow-conclusion--${this.status}`];
  }

  /**
   * Evento emitido ao iniciar a animação do template
   */
  @Emit('onAnimationStart')
  private animationStart() {
    // Função que emite evento onAnimationStart
  }

  /**
   * Evento emitido ao concluir a animação do template
   */
  @Emit('onAnimationEnd')
  private animationEnd() {
    // Função que emite evento onAnimationEnd
  }

  /**
   * Evento disparado ao clicar no botão de 'Fechar' na NavBar
   */
  @Emit('close')
  emitClose() {
    // Função que emite evento
  }

  public mounted() {
    this.animationStart();
    setTimeout(this.animationEnd, 1500);
  }

  scrollY = 0;

  scrolledThrottled: (() => void) | null = null;

  get isTransparent() {
    return this.scrollY === 0;
  }

  get closeButtonColors() {
    return {
      background: NebraskaColors.elementSecondary,
      content: NebraskaColors.elementPrimary,
      alphas: {
        default: 0,
        hover: 0.08,
        active: 0.08
      }
    };
  }

  get conclusionStepClass() {
    return {
      'flow-conclusion__nav-bar': true,
      'flow-conclusion__nav-bar__fixed': !this.showNavbar
    };
  }

  scrolled() {
    this.scrollY = window ? window.scrollY : 0;
  }

  created() {
    this.scrolledThrottled = throttle(this.scrolled, 150);
    window.addEventListener('scroll', this.scrolledThrottled);
  }

  destroyed() {
    if (this.scrolledThrottled) {
      window.removeEventListener('scroll', this.scrolledThrottled);
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../styles/_utils';
@import '../../styles/_medias';

/* Common animations variables */
@initial-delay: 2s;
@classes: {
  positive: @transaction-success, @status-positive, @status-positive-background;
  negative: @transaction-error, @status-negative, @status-negative-background;
  information: @transaction-info, @status-info, @status-info-background;
};

@half-height: calc(50vh - 161px);

/* Generate animations */
.keyframe_generate(bg-color; {}; { background-color: @background-secondary; });
.keyframe_generate(fade-in; { opacity: 0; }; { opacity: 1; });
.keyframe_generate(visible-on; { visibility: hidden; }; { visibility: visible; });
.keyframe_generate(slide-up; { transform: translateY(@half-height); }; { transform: translateY(25%); });
.keyframe_generate(slide-up-details; { transform: translateY(@half-height); }; {  margin-bottom: @size-spacing-x1800; });
.keyframe_generate(slide-up-quarter; { transform: translateY(40px); }; { transform: translateY(0%); });
.keyframe_generate(slide-up-button;  { transform: translateY(100%); }; { transform: translateY(0%); });

/* Component style */
.flow-conclusion {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &__nav-bar {
    visibility: hidden;
    .keyframe_animate(visible-on; 0.2s);

    &__fixed {
      border-bottom: none;
    }
  }

  // create nested --positive, --information, --negative rules with deep icon style
  each(@classes, {
      &--@{key} {
        @color: extract(@value, 3);
  
        background-color: @color;
        .keyframe_animate(bg-color; .5s);

        /deep/.initial-avatar-icon {
          @background-color: extract(@value, 1);
          color: @color;
          background-color: @background-color;
        }
      }
    });

  &__header {
    transform: translateY(@half-height);
    display: flex;
    justify-content: center;
    margin-bottom: @size-spacing-x1000;
    .keyframe_animate(slide-up; 0.5s);
  }

  &__helper-view {
    max-width: 100%;
    opacity: 0;
    .keyframe_animate(fade-in; 0.5s; 0.5s);
    .keyframe_animate(slide-up-quarter; 0.5s; 0.5s);
  }

  /deep/.helper-view__title .heading {
    .text-h-2();
  }

  /deep/.helper-view__description .base-text {
    opacity: 0;
    .keyframe_animate(fade-in; 0.5s);
  }

  &__container {
    padding: 0 @size-spacing-x500;

    .to-lg({ padding: 0 @size-spacing-x400;});
  }

  &__content {
    max-width: 592px;
    margin: 0 auto;
  }

  &__details {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    .keyframe_animate(slide-up-details; 0.5s);
    .keyframe_animate(fade-in; 0.5s);
  }

  &__button-container {
    transform: translateY(100%);
    background-color: @background-secondary;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 20;
    .keyframe_animate(slide-up-button; 0.5s);
  }
}
</style>
