<template>
  <label class="filter-option">
    <input
      :checked="isActive"
      :aria-disabled="disabled"
      :type="type"
      :value="value"
      @click="handleClick"
    />
    <FilterFoundation
      class="filter-option__button"
      :active="isActive"
      :disabled="disabled"
      :label="label"
    >
      <template #left-slot>
        <BaseIcon
          v-if="icon"
          colors="currentColor"
          width="20"
          height="20"
          :icon="icon"
        />
        <div
          v-else-if="counter > 0"
          :class="['counter', 'text-p-6', { 'counter--checked': isActive }]"
        >
          {{ formattedCounter }}
        </div>
      </template>
    </FilterFoundation>
  </label>
</template>

<script lang="ts">
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import FilterFoundation from '@/foundation/filter/FilterFoundation.vue';
import { IFilterOptionValue, FilterOptionType } from './types';

@Component({
  name: 'FilterOption',
  model: {
    prop: 'checked',
    event: 'change'
  },
  components: { FilterFoundation, BaseIcon }
})
export default class FilterOption extends Vue {
  /**
   * Indica se o filtro foi selecionado
   */
  @Prop({ type: [Array, String, Boolean], required: false })
  private checked!: IFilterOptionValue;

  /**
   * Indica o label do filtro
   */
  @Prop({ type: String, required: true })
  private label!: string;

  /**
   * Indica o valor do filtro
   */
  @Prop({ type: String, required: true })
  private value!: string;

  /**
   * Indica o valor do filtro
   */
  @Prop({ type: Number, required: false, default: 0 })
  private counter!: number;

  /**
   * Indica se o filtro deve estar desabilitado
   */
  @Prop({ type: Boolean, default: false })
  private disabled!: boolean;

  /**
   * Tipo do input renderizado dentro do Option
   * @values radio, checkbox
   */
  @Prop({ type: String, default: 'radio' })
  private type!: FilterOptionType;

  /**
   * Ícone a ser exibido à esquerda do label
   */
  @Prop({ type: String, required: false })
  private icon?: string;

  /**
   * Contador customizado quando o limite for atingido
   */
  @Prop({ type: String, default: '+9' })
  private counterOverflow!: string;

  /**
   * Evento emitido a cada click/mudança de estado
   */
  @Emit('change')
  public change(value: IFilterOptionValue) {
    return value;
  }

  handleClick(event: Event) {
    // Previne o browser de setar o input como marcado quando desabilitado,
    // pois a gente utiliza o aria-disabled ao invés do atributo disabled
    if (this.disabled) return false;

    const target = event.target as HTMLInputElement;
    const value = target.value;

    if (this.type === 'checkbox') {
      if (Array.isArray(this.checked)) {
        const index = this.checked.findIndex(e => e === value);

        if (index === -1) {
          const newChecked = this.checked.concat([value]);
          this.change(newChecked);
        } else {
          const newChecked = this.checked
            .slice(0, index)
            .concat(this.checked.slice(index + 1));
          this.change(newChecked);
        }
      } else {
        this.change(target.checked);
      }
    } else if (this.type === 'radio') {
      this.change(value);
    }

    return true;
  }

  get isActive() {
    if (Array.isArray(this.checked)) {
      return this.checked.includes(this.value);
    } else if (typeof this.checked === 'boolean') {
      return this.checked;
    } else {
      return this.checked === this.value;
    }
  }

  get formattedCounter() {
    return this.counter > 9 ? this.counterOverflow : this.counter.toString();
  }
}
</script>

<style lang="less" scoped>
.filter-option {
  position: relative;
  z-index: 0;

  input {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  input:focus + .filter-option__button {
    box-shadow: 0px 0px 0px 2px @element-primary,
      0px 0px 1px 0 inset @element-primary;
  }

  .counter {
    border-radius: @size-radius-max;
    padding: @size-spacing-x75;
    width: 18px;
    height: 18px;
    background-color: @element-primary;
    color: @base-primary;
    display: flex;
    justify-content: center;
    align-items: center;

    &--checked {
      background-color: @base-primary;
      color: @element-primary;
    }
  }
}
</style>
