<template>
  <div class="category-card">
    <template v-if="!loading">
      <div class="category-card-primary-view" @click="emitClickEvent">
        <div
          class="content"
          data-testid="primary-view"
          :class="computedClasses"
        >
          <div>
            <!-- @slot Espaço para adicionar conteúdo na parte superior do card -->
            <slot name="main" />
          </div>
          <div>
            <!-- @slot Espaço para adicionar conteúdo na parte inferior do card -->
            <slot name="footer" />
          </div>
        </div>
      </div>
      <div
        data-testid="secondary-view"
        class="category-card-secondary-view"
        :class="computedClasses"
        :style="computedStyle"
      >
        <div class="secondary-view-content" :class="computedClasses">
          <div>
            <!-- @slot Espaço para adicionar conteúdo na parte superior do card em sua visualização secundária -->
            <slot name="secondary-main" />
          </div>
          <div>
            <!-- @slot Espaço para adicionar conteúdo na parte inferior do card em sua visualização secundária -->
            <slot name="secondary-footer" />
          </div>
        </div>
      </div>
      <div
        class="trigger"
        data-testid="trigger"
        :class="triggerElementClasses"
        :style="computedStyle"
        @click="toggleLayout"
      >
        <BaseIcon
          :icon="icon"
          :colors="NebraskaColors.textOverImage"
          height="32px"
          width="32px"
        />
        <BaseIcon
          v-if="!secondaryViewDisabled"
          class="direction-icon"
          :class="computedClasses"
          icon="EA0170"
          :colors="NebraskaColors.textOverImage"
          height="32px"
          width="32px"
        />
      </div>
    </template>
    <template v-else>
      <SkeletonLoader
        data-testid="loader"
        show-animation
        width="100%"
        height="300px"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { NebraskaColors } from '@warrenbrasil/nebraska-tokens-web';
import BaseIcon from '@/foundation/base-icon/BaseIcon.vue';
import SkeletonLoader from '@/components/skeleton-loader/SkeletonLoader.vue';

@Component({ components: { BaseIcon, SkeletonLoader } })
export default class CategoryCard extends Vue {
  @Prop({ type: String, default: '' })
  private readonly icon!: string;

  @Prop({ type: String, default: NebraskaColors.asset04 })
  readonly color!: NebraskaColors;

  @Prop({ type: Boolean, default: false })
  readonly secondaryViewDisabled!: boolean;

  @Prop({ type: Boolean, default: false })
  private readonly loading!: boolean;

  @Emit('on-click')
  public emitClickEvent() {}

  readonly NebraskaColors = NebraskaColors;

  public isSecondaryView = false;

  get computedStyle() {
    return `background-color: ${this.color};`;
  }

  get triggerElementClasses() {
    return {
      secondary: this.isSecondaryView,
      disabled: this.secondaryViewDisabled
    };
  }

  get computedClasses() {
    return {
      secondary: this.isSecondaryView
    };
  }

  public toggleLayout() {
    if (this.secondaryViewDisabled) return;
    this.isSecondaryView = !this.isSecondaryView;
  }
}
</script>

<style scoped lang="less">
@trigger-element-width: 80px;
@card-transition-seconds: 0.7s;

.category-card {
  width: 100%;
  height: 300px;
  position: relative;
  border-radius: @size-radius-x300;
  overflow: hidden;

  .category-card-primary-view {
    position: absolute;
    width: calc(100% - @trigger-element-width);
    height: 100%;
    background-color: @background-tertiary;
    top: 0;
    left: 0;
    z-index: 1;
    padding: @size-spacing-x600;
    cursor: pointer;
    box-sizing: border-box;
    transition: background-color 0.3s ease;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      opacity: 1;
      transition: all ease-in-out 0.5s;
      &.secondary {
        opacity: 0.5;
      }
    }

    &:hover {
      background-color: @background-hover;
    }
  }

  .category-card-secondary-view {
    width: calc(100% - @trigger-element-width);
    height: 100%;
    position: absolute;
    top: 0;
    right: calc(-100% + @trigger-element-width);
    z-index: 2;
    overflow: hidden;
    transition: right @card-transition-seconds ease, opacity 0.3s ease;
    padding: @size-spacing-x600 @size-spacing-x600 @size-spacing-x600 0;
    box-sizing: border-box;

    &.secondary {
      right: 0;
    }

    .secondary-view-content {
      opacity: 0.5;
      transition: opacity 0.5s ease;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      &.secondary {
        opacity: 1;
      }
    }
  }

  .trigger {
    width: @trigger-element-width;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 3;
    cursor: pointer;
    transition: right @card-transition-seconds ease;
    padding: @size-spacing-x600 @size-spacing-x500;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;

    &.disabled {
      cursor: default;
    }

    &.secondary {
      right: calc(100% - @trigger-element-width);
    }
  }

  .direction-icon {
    transition: transform @card-transition-seconds ease;
    transform: rotate(180deg);

    &.secondary {
      transition: transform @card-transition-seconds ease;
      transform: rotate(0deg);
    }
  }
}
</style>
